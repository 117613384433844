/* You can add global styles to this file, and also import other style files */

@import "./../node_modules/@angular/material/prebuilt-themes/indigo-pink.css";

@font-face {
  font-family: bahnschrift;
  src: url(./assets/font/BAHNSCHRIFT.TTF) format("truetype");
}

* {
  font-family: bahnschrift, serif;
}

/* for sidenav to take a whole page */
html, body {
  margin: 0;
  height: 100%;
  width: 100%!important;
}

p {
  margin-block-start: 2px;
  margin-block-end:  2px;
}

.loginDialog .mat-dialog-container {
  background-image: linear-gradient(to right, rgb(0,48,91), rgb(0,27,55)) ;
  border: 1px solid white;
  border-radius: 10px;
  color: white;
  fill: white;
  max-height: 90%;
}

.loginDialogGray .mat-dialog-container {
  background: rgb(230,230,230);
  border: 1px solid black;
  border-radius: 10px;
  color: #464f56 !important;
  fill: black;
  max-height: 90%;
}

.settingControl .mat-dialog-container {
  background: rgb(230,230,230);
  border: 1px solid black;
  border-radius: 10px;
  color: #464f56 !important;
  fill: black;
  max-height: 90%;
  position: absolute;
  left: 10px;
  top: 20px;
  width: auto;
  height: auto;
}

::-webkit-scrollbar {
  width: 0px !important;
}

.activeColour:active {
  filter: invert(53%) sepia(94%) saturate(592%) hue-rotate(161deg) brightness(95%) contrast(94%) !important;
}

.inventoryTheme {
  background-image: linear-gradient(90deg ,#5d5d5d,#5d5d5d);
  fill: white!important;
}

.conveyorSelectionBG {
    background-image: linear-gradient(90deg ,#193153, #1a2b3c);
    border: 2px solid white;
    border-radius: 5px;
}
